import { Outlet, Link } from "react-router-dom";
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import IconeHay from "../images/logo-loading.png"; 
import ApiService from "../service/ApiService";
import '../App.css';
import UtilService from "../service/UtilService";

import { toast } from 'react-toastify';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contrato: {}
        }
    }   
    render() {
        return (
            <>
                <div className="test-container">
                    <Outlet /> 
                </div>
            </>
        )
    }
}
export default Layout;