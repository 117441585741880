import axios from 'axios';
import UtilService from "./UtilService";
import AsyncStorageService from "./AsyncStorageService";

class ApiService {
    constructor(){
        this.api = axios.create({
            baseURL: this.urlBase,
            headers: {
                //Authorization: "UHwrPRWSHq6Vm62d8FX4W", // Homologação
                Authorization: 'x3w_upcrH8ipEYAG5W7Ov' // Producao
            }
        });
    }
    urlBase = "https://portalifast.haysystems.com.br"; // URL OFICIAL
    //urlBase = "http://192.168.15.10:1337"; // URL DESENVOLVIMENTO
    //urlBase = "http://177.170.25.178:1337"; // URL TESTES
    mensagemDefault = "Ops, parece que você está tendo problemas de conectividade, ou há algum problema em seu acesso. Tente novamente mais tarde ou entre em contato com nosso suporte.";
    liberacaoEmConfianca = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.autoatendimento";
        const retorno = await this.api.post('/hay/clienteContrato/liberacaoEmConfianca', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    cancelarTransacao = async(parametros) => {
        UtilService.showLoading("Iniciando cancelamento de transação...");
        const retorno = await this.api.post("/hay/operacaoTotem/cancelarTransacao", parametros).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    iniciarAtendimento = async(parametros) => {
        UtilService.showLoading("Iniciando a solicitação de atendimento...");
        const retorno = await this.api.post("/hay/operacaoTotem/iniciarAtendimento", parametros).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    meLigue = async(parametros) => {
        UtilService.showLoading("Iniciando a solicitação de ligação...");
        const retorno = await this.api.post("/hay/operacaoTotem/meLigue", parametros).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    imprimirSegundaVia = async(parametros) => {
        UtilService.showLoading("Iniciando a Impressão...");
        const retorno = await this.api.post("/hay/operacaoTotem/imprimirSegundaVia", parametros).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    
    iniciarTransacao = async(parametros, cbCancelamento) => {
        var mensagem = "";
        const controller = new AbortController();
        UtilService.showLoading("Iniciando a transação de pagamento...", () => {
            mensagem = "Comando Cancelado"
            controller.abort();
            return cbCancelamento && cbCancelamento();
        });
        const retorno = await this.api.post("/hay/operacaoTotem/iniciarTransacao", parametros,{
            signal: controller.signal
        }).catch(e => {
            console.log(e.request.data);
            //alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        } else {
            return { ultimamensagem: mensagem }
        }
    }
    validarParametrosTotem = async (id) => {
        UtilService.showLoading("Verificando as configurações do atendimento...");
        const retorno = await this.api.get("/hay/operacaoTotem/parametro/" + id).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    verificarCpfCnpj = async (cpfCnpj) => {
        UtilService.showLoading("Buscando informações do contratuais...");
        const retorno = await this.api.post("/hay/cliente/verificarCpfCnpj", {
            cpfCnpj: cpfCnpj
        }).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    autorizarContratoPorNumero = async (params) => {
        UtilService.showLoading("Autorizando os contratos encontrados...");
        params.tecnologiaorigem = "main.tecnologiaorigem.autoatendimento";
        const retorno = await this.api.post('/hay/cliente/autorizarContratoPorNumero', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    buscarFaturasPorContrato = async (params) => {
        UtilService.showLoading("Buscando faturas dos contratos...");
        params.tecnologiaorigem = "main.tecnologiaorigem.autoatendimento";
        const retorno = await this.api.post('/hay/cliente/buscarFaturasPorContrato', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    gerarLinkMonitoramento = async (params) => {        
        const api = axios.create({
            baseURL: params.configuracoesMonitoramento.urlMonitoramento,
            headers: {
                //Authorization: "UHwrPRWSHq6Vm62d8FX4W", // Homologação
                Authorization: params.configuracoesMonitoramento.tokenMonitoramento, // Producao
            }
        });
        UtilService.showLoading(false);
        const retorno = await api.post("/hay/monitoramentoLink", {
            monitoramentotemplate: params.configuracoesMonitoramento.templateMonitoramento,
            status: "main.monitoramentolink.status.aguardandoinicio",
            documentocliente: params.cliente.cpfCnpj,
            identificadorterceiros: params.clientecontrato.idexterno,
            nomecliente: params.cliente.nome,
        }).catch(e => {
            console.log(e.request);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    consultarEquipamentos = async (idcontrato) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post("/hay/clienteContrato/consultarEquipamentos", {
            idcontrato: idcontrato
        }).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    
    alterarInformacoesEquipamento = async (params) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post("/hay/clienteContrato/alterarInformacoesEquipamento", params).catch(e => {
            console.log(e.request.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    verificaNumerosPorContrato = async (idcontrato, chavebase, cpfCnpj) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post("/hay/cliente/verificaNumerosPorContrato", {
            "idcontrato": idcontrato,
            "chavebase": chavebase,
            "cpfCnpj": cpfCnpj
        }).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }   
    }
    verificarEmailsPorContrato = async (idcontrato, chavebase, cpfCnpj) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post("/hay/cliente/verificarEmailsPorContrato", {
            "idcontrato": idcontrato,
            "chavebase": chavebase,
            "cpfCnpj": cpfCnpj
        }).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }   
    }
    registrarUsuarioCentralAssinante = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        console.log(params);
        const retorno = await this.api.post("/hay/usuario/registrarUsuarioCentralAssinante", {
            "idcontrato": params.idcontrato,
            "chavebase": params.chavebase,
            "cpfCnpj": params.cpfCnpj,
            "tipo": params.tipo,
            "identificacao": params.identificacao,
            "nome": params.nome,
            "email": params.email,
            "telefone": params.telefone,
            "pass": params.pass,
            "passConf": params.passConf,
        }).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    validarPinInformado = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/validarPinInformado", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    
    gerarPinAlteracaoTelefone = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/gerarPinAlteracaoTelefone", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    
    gerarPinAlteracaoEmail = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/gerarPinAlteracaoEmail", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    
    
    validarPinContato = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/validarPinContato", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    atualizarTelefones = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/atualizarTelefones", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    removerContato = async (id) => {
        UtilService.showLoading(false);
        const retorno = await this.api.delete("/hay/clientecontato/" + id).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    atualizarEmails = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/cliente/atualizarEmails", params).catch(e => {
            console.log(e.response.data);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    loginCentralAssinante = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/usuario/loginCentralAssinante", params).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    loginCentralAssinanteToken = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/usuario/loginCentralAssinanteToken", params).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }             
    }
    gerarPinResetSenhaCentral = async (params) => {
        console.log(params);
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/usuario/gerarPinResetSenhaCentral", params).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    alterarSenhaPin = async (params) => {
        console.log(params);
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post("/hay/usuario/alterarSenhaPin", params).catch(e => {
            console.log(e);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    buscarPromocoesAtivas = async () => {
        //console.log(params);
        UtilService.showLoading(false);
        const retorno = await this.api.get('/hay/promocao?where={"ativo":true}').catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data && retorno.data.registros){
            return retorno.data.registros;
        }
    }
    validarInformacoesContratuais = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clienteContrato/validarInformacoesContratuais',params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    enviarFaturaPorEmail = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clienteContrato/enviarFaturaPorEmail',params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    enviarFaturaPorSms = async (params) => {        
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clienteContrato/enviarFaturaPorSms',params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    liberacaoEmConfianca = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clienteContrato/liberacaoEmConfianca', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    atualizarFromToken = async (params) => {
        UtilService.showLoading(false);
        params.token = await AsyncStorageService.getKeyFromStorage("@token");
        const retorno = await this.api.post('/hay/usuario/atualizarFromToken', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    alterarDataVencimento = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clienteContrato/alterarDataVencimento', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    alterarSenha = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        params.token = await AsyncStorageService.getKeyFromStorage("@token");
        const retorno = await this.api.post('/hay/usuario/alterarSenha', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    historicoFaturas = async (params) => {
        console.log(params);
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/cliente/historicoFaturas', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    consultarClienteIPay = async (params) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post('/hay/clientecontrato/consultarClienteIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    cadastrarClienteIPay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/cadastrarClienteIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    sincronizarClienteIpay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/sincronizarClienteIpay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    consultarCartoesIPay = async (params) => {
        UtilService.showLoading(false);        
        const retorno = await this.api.post('/hay/clientecontrato/consultarCartoesIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    consultarTransacoesIPay = async (params) => {
        UtilService.showLoading(false);
        const retorno = await this.api.post('/hay/clientecontrato/consultarTransacoesIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    adicionarCartaoIPay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/adicionarCartaoIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    removerCartaoIPay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/removerCartaoIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }

    ativarCobrancaRecorrenteIPay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/ativarCobrancaRecorrenteIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    getVideos = async () => {
        UtilService.showLoading(false);
        const retorno = await this.api.get('/hay/video?limit=20&skip=0&sort=ordem+DESC&where={"ativo":true}').catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data && retorno.data.registros){
            return retorno.data.registros;
        }
    }
    criarTransacaoIPay = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/criarTransacaoIPay', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    removerMeuAcesso = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/usuario/removerMeuAcesso', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    autorizarContratoPorNumeroCentralAssinante = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/usuario/autorizarContratoPorNumeroCentralAssinante', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
    alterarFormaPagamento = async (params) => {
        UtilService.showLoading(false);
        params.tecnologiaorigem = "main.tecnologiaorigem.central.assinante";
        const retorno = await this.api.post('/hay/clientecontrato/alterarFormaPagamento', params).catch(e => {
            console.log(e.response);
            alert("Falha de Acesso", this.mensagemDefault);
        });    
        UtilService.hideLoading();
        if (retorno && retorno.data){
            return retorno.data;
        }
    }
}
export default new ApiService();