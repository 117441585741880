import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';
import UtilService from '../service/UtilService';
import { Link } from 'react-router-dom';

class Identificacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cpfCnpj: ""//"41623184843" //'46768989081'
        }
    }
    pressionar(numero) {
        this.setState({ cpfCnpj: this.state.cpfCnpj + String(numero) });
    }
    apagar() {
        if (this.state.cpfCnpj) {
            this.setState({ cpfCnpj: this.state.cpfCnpj.slice(0, -1) });
        }
    }
    async buscarContrato() {
        var contratos = await ApiService.verificarCpfCnpj(this.state.cpfCnpj);
        if (contratos.state){
            contratos = [];
        }
        
        var contratosAutorizados = [];
        for (let contrato of contratos) {
            const contratoAutorizado = await ApiService.autorizarContratoPorNumero({ cpfCnpj: this.state.cpfCnpj, numerocontrato: contrato.numerocontrato });
            if (contratoAutorizado) {
                contratosAutorizados.push({ ...contrato, ...{ contratoid: contratoAutorizado.idcontrato, state: contratoAutorizado.state, cpfCnpj: this.state.cpfCnpj } });
            }
        }
        this.props.onSuccess && this.props.onSuccess(contratosAutorizados);
        if (contratosAutorizados.length > 1) {
            UtilService.iniciarTimerSessao(this.props.aoExpirar, toast);

            return this.props.navigate("/menuinicial");
        }
        else if (contratosAutorizados.length == 1) {
            var contrato = contratosAutorizados[0];
            contrato.cpfCnpj = this.state.cpfCnpj;
            const faturas = await ApiService.buscarFaturasPorContrato({ idcontrato: contrato.contratoid }) || [];
            this.props.onSucessUmContrato && this.props.onSucessUmContrato({ ...contrato, ...faturas });
            UtilService.iniciarTimerSessao(this.props.aoExpirar, toast);
            return this.props.navigate("/contratoselecionado");
        }
        else {
            toast.error("Nenhum contrato foi encontrado com esse cpf cnpj", {
                autoClose: false,
                position: "top-center",
            });
        }
    }
    render() {
        return (
            <>
                <ul id="progress-bar" className="entry-bookend-progress-container header fundo-inicial" >
                    <p className="boas-vindas"> Digite o CPF ou CNPJ: </p>
                    <span className="entry-bookend-progress-container header" style={{ 
                        paddingBottom: 0, 
                        borderBottomLeftRadius: 0, 
                        borderBottomRightRadius: 0,
                        width: "70%", 
                        fontSize: "xx-large",
                        fontWeight: 900  
                    }}>{this.state.cpfCnpj}</span>
                    <ul id="progress-bar" className="entry-bookend-progress-container header" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: "70%" }}>
                        <ul className="entry-bookend-progress-container header"  style={{ width: "100%" }}>
                            <li className="progress-item progress-step" title={"Pressionar 1"} onClick={() => this.pressionar(1)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 1 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 2"} onClick={() => this.pressionar(2)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 2 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 3"} onClick={() => this.pressionar(3)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 3 </span>
                                    </span>
                                </span>
                            </li>
                            <li className='break'></li>
                            <li className="progress-item progress-step" title={"Pressionar 4"} onClick={() => this.pressionar(4)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 4 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 5"} onClick={() => this.pressionar(5)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 5 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 6"} onClick={() => this.pressionar(6)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 6 </span>
                                    </span>
                                </span>
                            </li>
                            <li className='break'></li>
                            <li className="progress-item progress-step" title={"Pressionar 7"} onClick={() => this.pressionar(7)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 7 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 8"} onClick={() => this.pressionar(8)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 8 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 9"} onClick={() => this.pressionar(9)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 9 </span>
                                    </span>
                                </span>
                            </li>
                            <li className='break'></li>
                            <li className="progress-item progress-step" title={"Apagar"} onClick={() => this.apagar()}>

                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="2x" icon={"circle"} transform="right-3" style={{ color: "red" }} />
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="4x" icon={"delete-left"} />
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Pressionar 0"} onClick={() => this.pressionar(0)}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} />
                                        <span class="fa-layers-text fa-2x unselectable" style={{ fontWeight: 900 }}> 0 </span>
                                    </span>
                                </span>
                            </li>
                            <li className="progress-item progress-step" title={"Buscar Contrato"} onClick={() => this.buscarContrato()}>
                                <span className="circle progress-index numero" >
                                    <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle"} style={{ color: "lime" }} />
                                        <FontAwesomeIcon className={"fa-fw index unselectable"} size="5x" icon={"circle-check"} />
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </ul>
                    <Link to="/" className="botao-autoatendimento terciario"  onClick={()=> this.props.aoExpirar() }>
                        Encerrar Atendimento
                    </Link>
                </ul>

            </>
        )
    }
}
export default withRouter(Identificacao);